import axios from 'axios';
export const state = {
    user: null,
    token: localStorage.getItem('token') || null,
}

export const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setToken(state, token) {
        state.token = token;
        localStorage.setItem('token', token);
    },
    clearAuth(state) {
        state.user = null;
        state.token = null;
        localStorage.removeItem('token');
    },
}

export const getters = {
    isAuthenticated(state) {
        return !!state.user;
    },
    user: state => state.user,
}

export const actions = {
    async login({ commit }, values) {
        const response = await axios.post('/login', values);
        commit('setUser', response.data.user);
        commit('setToken', response.data.token);
    },
    async logout({ commit }) {
        await axios.post('/logout');
        commit('clearAuth');
    },
    async fetchUser({ commit, state }) {
        if (state.token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
            try {
                const response = await axios.get('/user');
                commit('setUser', response.data);
            } catch (error) {
                commit('clearAuth');
            }
        }
    },


}