import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: "/",
    name: "home-page",
    meta: { title: "Home", requiresAuth: true },
    component: () => import("../views/home.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Auth/Login"),
    meta: { title: "Login" },
  },
  {
    path: "/profile",
    name: "profile",
    meta: { title: "Profile", requiresAuth: true },
    component: () => import("../views/Profile/ProfileUser"),
  },
  {
    path: "/profile-setting",
    name: "profile-setting",
    meta: { title: "Setting", requiresAuth: true },
    component: () => import("../views/Profile/setting"),
  },
  {
    path: "/employees",
    name: "Employees",
    meta: { title: "Employees", requiresAuth: true },
    component: () => import("../views/Employees/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
