import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import i18n from './i18n';
import store from "./state/store";
import BootstrapVueNext from 'bootstrap-vue-next';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import '@/assets/scss/app.scss';
//import '@vueform/slider/themes/default.css';
import 'bootstrap/dist/js/bootstrap.bundle' ;
import axios from '../axios-config'; 

const app = createApp(App);

app.config.globalProperties.$axios = axios;

console.log('VUE_APP_BASE_URL', process.env.VUE_APP_BASE_URL);

 createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(BootstrapVueNext)
    .use(router).mount('#app')


